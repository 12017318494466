export const state = () => ({
  anonymousId: '',
  userAgent: '',
  device: '',
});

export const mutations = {
  setAnonymousId(state, anonymousId) {
    state.anonymousId = anonymousId;
  },
  setUserAgent(state, userAgent) {
    state.userAgent = userAgent;
  },
  setDevice(state, device) {
    state.device = device;
  },
};

export const getters = {
  attributes(state, _, { session }) {
    return {
      user_agent: state.userAgent,
      currency: session.currency.code,
      language: session.language.tag,
      device: state.device,
    };
  },
};
