<template>
  <div>
    <wds-accordion>
      <wds-accordion-item data-testid="accommodation-accordion">
        <div slot="header">
          {{ $t('t_ACCOMMODATION') }}
        </div>
        <div class="h-menu-link-list" slot="content">
          <ul>
            <li>
              <a :href="buildWorldPageUrl('t_SLUGPROPERTYTYPEHOSTELS')" :title="$t('t_HOSTELS')">{{
                $t('t_HOSTELS')
              }}</a>
            </li>
            <li>
              <a :href="buildWorldPageUrl('t_SLUGPROPERTYTYPEHOTELS')" :title="$t('t_HOTELS')">{{ $t('t_HOTELS') }}</a>
            </li>
            <li>
              <a :href="buildWorldPageUrl('t_SLUGPROPERTYTYPEBNB')" :title="$t('t_GUESTHOUSE')">{{
                $t('t_GUESTHOUSE')
              }}</a>
            </li>
          </ul>
        </div>
      </wds-accordion-item>
      <wds-accordion-item data-testid="explore-hostelworld-accordion">
        <div slot="header">{{ $t('t_EXPLOREHOSTELWORLD') }}</div>
        <div class="h-menu-link-list" slot="content">
          <ul>
            <li>
              <a :href="$t('t_URLBLOG')" :title="$t('t_BLOG')" rel="noopener noreferrer" target="_blank">
                {{ $t('t_BLOG') }}
              </a>
            </li>
            <li>
              <a :href="buildGuaranteeUrl" :title="$t('t_BOOKINGGUARANTEE')" rel="noopener noreferrer" target="_blank">
                {{ $t('t_BOOKINGGUARANTEE') }}
              </a>
            </li>
            <li>
              <a
                :href="$t('t_HOSCARAWARDSURL')"
                :title="$t('t_HOSCARAWARDS')"
                rel="noopener noreferrer"
                target="_blank"
              >
                {{ $t('t_HOSCARAWARDS') }}
              </a>
            </li>
          </ul>
        </div>
      </wds-accordion-item>
      <wds-accordion-item data-testid="work-with-us-accordion">
        <div slot="header">{{ $t('t_WORKWITHUS') }}</div>
        <div class="h-menu-link-list" slot="content">
          <ul>
            <li>
              <a :href="$t('t_HWSIGNUPURL')" :title="$t('t_HOSTELSIGNUP')" rel="noopener noreferrer" target="_blank">
                {{ $t('t_HOSTELSIGNUP') }}
              </a>
            </li>
            <li>
              <a
                :href="$t('t_AFFILIATEPROGRAMURL')"
                :title="$t('t_AFFILIATEPROGRAM')"
                rel="noopener noreferrer"
                target="_blank"
              >
                {{ $t('t_AFFILIATEPROGRAM') }}
              </a>
            </li>
            <li>
              <a :href="$t('t_INBOXLOGINURL')" :title="$t('t_INBOXLOGIN')" rel="noopener noreferrer" target="_blank">
                {{ $t('t_INBOXLOGIN') }}
              </a>
            </li>
          </ul>
        </div>
      </wds-accordion-item>
    </wds-accordion>
    <a
      :href="$t('t_CONTACTUSURL')"
      :title="$t('t_HELP')"
      class="h-help-link"
      rel="noopener noreferrer"
      target="_blank"
      data-testid="help-link"
    >
      <wds-icon asset="question" />
      <span>{{ $t('t_HELP') }}</span>
    </a>
  </div>
</template>

<script>
import { WdsIcon, WdsAccordion, WdsAccordionItem } from '@wds/components';

export default {
  name: 'HamburguerMenuLinks',

  components: {
    WdsIcon,
    WdsAccordion,
    WdsAccordionItem,
  },

  computed: {
    buildGuaranteeUrl() {
      return this.$url.getGenericPageUrl('guarantee');
    },
  },

  methods: {
    buildWorldPageUrl(propertyTypeSlug) {
      return this.$url.getWorldPageUrl({ propertyTypeSlug: this.$t(propertyTypeSlug) });
    },
  },
};
</script>

<style lang="scss" scoped>
.h-menu-link-list {
  width: 100%;

  li a {
    display: block;
    padding: wds-rem(12px) $wds-spacing-l;
    margin: $wds-spacing-xs 0;
    border: none;
    border-radius: $wds-border-radius-xl;
    outline: none;
    background-color: $wds-color-white;
    color: $wds-color-ink-darker;
    fill: $wds-color-ink-darker;

    &:hover {
      background-color: $wds-color-ink-lightest;
      text-decoration: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 wds-rem(2px) $wds-color-white, 0 0 0 wds-rem(4px) $wds-color-ink-darker;
    }
  }
}

.h-help-link {
  display: flex;
  align-items: center;
  padding: wds-rem(14px) $wds-spacing-m;
  margin: 0 wds-rem(2px) $wds-spacing-s;
  border: none;
  border-radius: $wds-border-radius-xl;
  outline: none;
  background-color: $wds-color-white;
  color: $wds-color-ink-darker;
  fill: $wds-color-ink-darker;
  @include body-1-bld;

  &:hover {
    background-color: $wds-color-ink-lightest;
    text-decoration: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 wds-rem(2px) $wds-color-white, 0 0 0 wds-rem(4px) $wds-color-ink-darker;
  }

  span {
    padding-left: $wds-spacing-m;
  }

  wds-icon {
    display: inline-flex;
  }
}
</style>
