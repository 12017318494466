import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_a31d0246 from 'nuxt_plugin_sentryserver_a31d0246' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_48b80d55 from 'nuxt_plugin_sentryclient_48b80d55' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_nuxtmq_7fc446e1 from 'nuxt_plugin_nuxtmq_7fc446e1' // Source: ./nuxt-mq.js (mode: 'all')
import nuxt_plugin_optimizely_0a249596 from 'nuxt_plugin_optimizely_0a249596' // Source: ./optimizely.js (mode: 'all')
import nuxt_plugin_log_3615ec8b from 'nuxt_plugin_log_3615ec8b' // Source: ./log.js (mode: 'server')
import nuxt_plugin_workbox_a8208a16 from 'nuxt_plugin_workbox_a8208a16' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_a72585f0 from 'nuxt_plugin_metaplugin_a72585f0' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_9dd63b08 from 'nuxt_plugin_iconplugin_9dd63b08' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_httpserver_2e283374 from 'nuxt_plugin_httpserver_2e283374' // Source: ./http.server.js (mode: 'server')
import nuxt_plugin_http_7b2979d2 from 'nuxt_plugin_http_7b2979d2' // Source: ./http.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_27fb9012 from 'nuxt_plugin_nuxtsvgsprite_27fb9012' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_deviceplugin_5ed152b8 from 'nuxt_plugin_deviceplugin_5ed152b8' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_currency_f1310e6a from 'nuxt_plugin_currency_f1310e6a' // Source: ../src/plugins/core/currency (mode: 'all')
import nuxt_plugin_formatters_c090a9ba from 'nuxt_plugin_formatters_c090a9ba' // Source: ../src/plugins/core/formatters (mode: 'all')
import nuxt_plugin_env_51a4181a from 'nuxt_plugin_env_51a4181a' // Source: ../src/plugins/core/env (mode: 'all')
import nuxt_plugin_i18n_0e916fd8 from 'nuxt_plugin_i18n_0e916fd8' // Source: ../src/plugins/core/i18n (mode: 'all')
import nuxt_plugin_mobileTouchEvents_33d267bc from 'nuxt_plugin_mobileTouchEvents_33d267bc' // Source: ../src/plugins/core/mobileTouchEvents (mode: 'all')
import nuxt_plugin_structured_3d7875eb from 'nuxt_plugin_structured_3d7875eb' // Source: ../src/plugins/core/structured.markup (mode: 'all')
import nuxt_plugin_imageLazyLoad_2a335f0a from 'nuxt_plugin_imageLazyLoad_2a335f0a' // Source: ../src/plugins/core/imageLazyLoad (mode: 'all')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../src/plugins/api (mode: 'all')
import nuxt_plugin_url_5e466e39 from 'nuxt_plugin_url_5e466e39' // Source: ../src/plugins/url (mode: 'all')
import nuxt_plugin_linkTags_3a4510a9 from 'nuxt_plugin_linkTags_3a4510a9' // Source: ../src/plugins/linkTags (mode: 'all')
import nuxt_plugin_index_66c1fcf2 from 'nuxt_plugin_index_66c1fcf2' // Source: ../src/plugins/core/routes/index (mode: 'all')
import nuxt_plugin_index_edf42276 from 'nuxt_plugin_index_edf42276' // Source: ../src/plugins/core/segmentjs/index (mode: 'client')
import nuxt_plugin_tracking_9e54b11e from 'nuxt_plugin_tracking_9e54b11e' // Source: ../src/plugins/core/tracking (mode: 'client')
import nuxt_plugin_gtag_0e91878d from 'nuxt_plugin_gtag_0e91878d' // Source: ../src/plugins/core/gtag (mode: 'client')
import nuxt_plugin_outside_4eaa9a90 from 'nuxt_plugin_outside_4eaa9a90' // Source: ../src/plugins/directives/outside (mode: 'client')
import nuxt_plugin_channelAttribution_4155f3b6 from 'nuxt_plugin_channelAttribution_4155f3b6' // Source: ../src/plugins/core/channelAttribution (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"hostelworld","meta":[{"hid":"charset","charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"twitter:account_id","property":"twitter:account_id","content":"1512943059"},{"hid":"twitter:site","name":"twitter:site","content":"@hostelworld"},{"hid":"p:domain_verify","name":"p:domain_verify","content":"60173e59fcc4c2e1cbe975ad5081226c"},{"hid":"fb:admins","property":"fb:admins","content":"653765396"},{"hid":"msvalidate.01","name":"msvalidate.01","content":"B062587789B141D9BF3FED9B67A6B3F6"},{"hid":"apple-mobile-web-app-capable","name":"apple-mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-status-bar-style","name":"apple-mobile-web-app-status-bar-style","content":"black"}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Fapi.hostelworld.com","crossorigin":"crossorigin"},{"rel":"preconnect","href":"https:\u002F\u002Ficd.hwstatic.com","crossorigin":"crossorigin"},{"rel":"preconnect","href":"https:\u002F\u002Fucd.hwstatic.com","crossorigin":"crossorigin"},{"rel":"preconnect","href":"https:\u002F\u002Fa.hwstatic.com","crossorigin":"crossorigin"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com\u002F","crossorigin":"crossorigin"},{"rel":"preconnect","href":"https:\u002F\u002Fcdn.segment.com\u002F","crossorigin":"crossorigin"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fst\u002Ffavicon.ico"}],"__dangerouslyDisableSanitizers":["script","innerHTML"],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_a31d0246 === 'function') {
    await nuxt_plugin_sentryserver_a31d0246(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_48b80d55 === 'function') {
    await nuxt_plugin_sentryclient_48b80d55(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtmq_7fc446e1 === 'function') {
    await nuxt_plugin_nuxtmq_7fc446e1(app.context, inject)
  }

  if (typeof nuxt_plugin_optimizely_0a249596 === 'function') {
    await nuxt_plugin_optimizely_0a249596(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_log_3615ec8b === 'function') {
    await nuxt_plugin_log_3615ec8b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_a8208a16 === 'function') {
    await nuxt_plugin_workbox_a8208a16(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_a72585f0 === 'function') {
    await nuxt_plugin_metaplugin_a72585f0(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_9dd63b08 === 'function') {
    await nuxt_plugin_iconplugin_9dd63b08(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_httpserver_2e283374 === 'function') {
    await nuxt_plugin_httpserver_2e283374(app.context, inject)
  }

  if (typeof nuxt_plugin_http_7b2979d2 === 'function') {
    await nuxt_plugin_http_7b2979d2(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_27fb9012 === 'function') {
    await nuxt_plugin_nuxtsvgsprite_27fb9012(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_5ed152b8 === 'function') {
    await nuxt_plugin_deviceplugin_5ed152b8(app.context, inject)
  }

  if (typeof nuxt_plugin_currency_f1310e6a === 'function') {
    await nuxt_plugin_currency_f1310e6a(app.context, inject)
  }

  if (typeof nuxt_plugin_formatters_c090a9ba === 'function') {
    await nuxt_plugin_formatters_c090a9ba(app.context, inject)
  }

  if (typeof nuxt_plugin_env_51a4181a === 'function') {
    await nuxt_plugin_env_51a4181a(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_0e916fd8 === 'function') {
    await nuxt_plugin_i18n_0e916fd8(app.context, inject)
  }

  if (typeof nuxt_plugin_mobileTouchEvents_33d267bc === 'function') {
    await nuxt_plugin_mobileTouchEvents_33d267bc(app.context, inject)
  }

  if (typeof nuxt_plugin_structured_3d7875eb === 'function') {
    await nuxt_plugin_structured_3d7875eb(app.context, inject)
  }

  if (typeof nuxt_plugin_imageLazyLoad_2a335f0a === 'function') {
    await nuxt_plugin_imageLazyLoad_2a335f0a(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (typeof nuxt_plugin_url_5e466e39 === 'function') {
    await nuxt_plugin_url_5e466e39(app.context, inject)
  }

  if (typeof nuxt_plugin_linkTags_3a4510a9 === 'function') {
    await nuxt_plugin_linkTags_3a4510a9(app.context, inject)
  }

  if (typeof nuxt_plugin_index_66c1fcf2 === 'function') {
    await nuxt_plugin_index_66c1fcf2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_index_edf42276 === 'function') {
    await nuxt_plugin_index_edf42276(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tracking_9e54b11e === 'function') {
    await nuxt_plugin_tracking_9e54b11e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtag_0e91878d === 'function') {
    await nuxt_plugin_gtag_0e91878d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_outside_4eaa9a90 === 'function') {
    await nuxt_plugin_outside_4eaa9a90(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_channelAttribution_4155f3b6 === 'function') {
    await nuxt_plugin_channelAttribution_4155f3b6(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
