const modes = {
  server(context, inject) {
    inject('env', context.req.env);

    context.beforeNuxtRender(({ nuxtState }) => {
      nuxtState.env = context.req.env;
    });
  },

  client(context, inject) {
    const env = context.nuxtState !== undefined ? context.nuxtState.env : context.env;
    inject('env', env);
  },
};

export default (context, inject) => {
  const key = process.client ? 'client' : 'server';
  const strategy = modes[key];

  return strategy(context, inject);
};
