import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d66f09c0 = () => interopDefault(import('../src/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _6d134f46 = () => interopDefault(import('../src/pages/500.vue' /* webpackChunkName: "pages/500" */))
const _979c665e = () => interopDefault(import('../src/pages/backpackers/index.vue' /* webpackChunkName: "pages/backpackers/index" */))
const _530f6c4e = () => interopDefault(import('../src/pages/bestpriceguarantee/index.vue' /* webpackChunkName: "pages/bestpriceguarantee/index" */))
const _014194a8 = () => interopDefault(import('../src/pages/cookiespolicy/index.vue' /* webpackChunkName: "pages/cookiespolicy/index" */))
const _034f050c = () => interopDefault(import('../src/pages/groupsdataprotectionnotice/index.vue' /* webpackChunkName: "pages/groupsdataprotectionnotice/index" */))
const _585dfef3 = () => interopDefault(import('../src/pages/guarantee/index.vue' /* webpackChunkName: "pages/guarantee/index" */))
const _13d7fd42 = () => interopDefault(import('../src/pages/hosteltermsandconditions/index.vue' /* webpackChunkName: "pages/hosteltermsandconditions/index" */))
const _09ebb0c1 = () => interopDefault(import('../src/pages/mobile-app/index.vue' /* webpackChunkName: "pages/mobile-app/index" */))
const _190a85aa = () => interopDefault(import('../src/pages/mobile-app-download/index.vue' /* webpackChunkName: "pages/mobile-app-download/index" */))
const _602b2ea7 = () => interopDefault(import('../src/pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _93eb6234 = () => interopDefault(import('../src/pages/reviewguidelines/index.vue' /* webpackChunkName: "pages/reviewguidelines/index" */))
const _26a258b3 = () => interopDefault(import('../src/pages/securityprivacy/index.vue' /* webpackChunkName: "pages/securityprivacy/index" */))
const _46ab2640 = () => interopDefault(import('../src/pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _4faa886e = () => interopDefault(import('../src/pages/website-testing/index.vue' /* webpackChunkName: "pages/website-testing/index" */))
const _38c074aa = () => interopDefault(import('../src/pages/guarantee/terms/index.vue' /* webpackChunkName: "pages/guarantee/terms/index" */))
const _af682c8c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b3a8525e = () => interopDefault(import('../src/pages/2/_check/index.vue' /* webpackChunkName: "pages/2/_check/index" */))
const _0dfcd87e = () => interopDefault(import('../src/pages/hostel-group/_brand/index.vue' /* webpackChunkName: "pages/hostel-group/_brand/index" */))
const _1253bd0e = () => interopDefault(import('../src/pages/_propertyType/index.vue' /* webpackChunkName: "pages/_propertyType/index" */))
const _c102c3ec = () => interopDefault(import('../src/pages/_propertyType/p/_propertyId/_propertyName/index.vue' /* webpackChunkName: "pages/_propertyType/p/_propertyId/_propertyName/index" */))
const _30fdac03 = () => interopDefault(import('../src/pages/_propertyType/p/_propertyId/_propertyName/new-design.vue' /* webpackChunkName: "pages/_propertyType/p/_propertyId/_propertyName/new-design" */))
const _149f62a1 = () => interopDefault(import('../src/pages/_propertyType/_continent/index.vue' /* webpackChunkName: "pages/_propertyType/_continent/index" */))
const _7a00325b = () => interopDefault(import('../src/pages/_propertyType/_continent/_country/index.vue' /* webpackChunkName: "pages/_propertyType/_continent/_country/index" */))
const _cdc9fa6c = () => interopDefault(import('../src/pages/_propertyType/_continent/_country/a/_area/index.vue' /* webpackChunkName: "pages/_propertyType/_continent/_country/a/_area/index" */))
const _f0041c7c = () => interopDefault(import('../src/pages/_propertyType/_continent/_country/r/_region/index.vue' /* webpackChunkName: "pages/_propertyType/_continent/_country/r/_region/index" */))
const _1fd04478 = () => interopDefault(import('../src/pages/_propertyType/_continent/_country/_city/new-design_.vue' /* webpackChunkName: "pages/_propertyType/_continent/_country/_city/new-design_" */))
const _144d51d6 = () => interopDefault(import('../src/pages/_propertyType/_continent/_country/_city/d/_district/_.vue' /* webpackChunkName: "pages/_propertyType/_continent/_country/_city/d/_district/_" */))
const _493609ba = () => interopDefault(import('../src/pages/_propertyType/_continent/_country/_city/_.vue' /* webpackChunkName: "pages/_propertyType/_continent/_country/_city/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/st/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _d66f09c0,
    name: "404"
  }, {
    path: "/500",
    component: _6d134f46,
    name: "500"
  }, {
    path: "/backpackers",
    component: _979c665e,
    name: "backpackers"
  }, {
    path: "/bestpriceguarantee",
    component: _530f6c4e,
    name: "bestpriceguarantee"
  }, {
    path: "/cookiespolicy",
    component: _014194a8,
    name: "cookiespolicy"
  }, {
    path: "/groupsdataprotectionnotice",
    component: _034f050c,
    name: "groupsdataprotectionnotice"
  }, {
    path: "/guarantee",
    component: _585dfef3,
    name: "guarantee"
  }, {
    path: "/hosteltermsandconditions",
    component: _13d7fd42,
    name: "hosteltermsandconditions"
  }, {
    path: "/mobile-app",
    component: _09ebb0c1,
    name: "mobile-app"
  }, {
    path: "/mobile-app-download",
    component: _190a85aa,
    name: "mobile-app-download"
  }, {
    path: "/redirect",
    component: _602b2ea7,
    name: "redirect"
  }, {
    path: "/reviewguidelines",
    component: _93eb6234,
    name: "reviewguidelines"
  }, {
    path: "/securityprivacy",
    component: _26a258b3,
    name: "securityprivacy"
  }, {
    path: "/sitemap",
    component: _46ab2640,
    name: "sitemap"
  }, {
    path: "/website-testing",
    component: _4faa886e,
    name: "website-testing"
  }, {
    path: "/guarantee/terms",
    component: _38c074aa,
    name: "guarantee-terms"
  }, {
    path: "/",
    component: _af682c8c,
    name: "index"
  }, {
    path: "/2/:check",
    component: _b3a8525e,
    name: "2-check"
  }, {
    path: "/hostel-group/:brand",
    component: _0dfcd87e,
    name: "hostel-group-brand"
  }, {
    path: "/:propertyType",
    component: _1253bd0e,
    name: "propertyType"
  }, {
    path: "/:propertyType/p/:propertyId?/:propertyName",
    component: _c102c3ec,
    name: "propertyType-p-propertyId-propertyName"
  }, {
    path: "/:propertyType/p/:propertyId?/:propertyName?/new-design",
    component: _30fdac03,
    name: "propertyType-p-propertyId-propertyName-new-design"
  }, {
    path: "/:propertyType/:continent",
    component: _149f62a1,
    name: "propertyType-continent"
  }, {
    path: "/:propertyType/:continent/:country",
    component: _7a00325b,
    name: "propertyType-continent-country"
  }, {
    path: "/:propertyType/:continent/:country/a/:area",
    component: _cdc9fa6c,
    name: "propertyType-continent-country-a-area"
  }, {
    path: "/:propertyType/:continent/:country/r/:region",
    component: _f0041c7c,
    name: "propertyType-continent-country-r-region"
  }, {
    path: "/:propertyType/:continent/:country/:city/new-design_",
    component: _1fd04478,
    name: "propertyType-continent-country-city-new-design_"
  }, {
    path: "/:propertyType/:continent/:country/:city/d/:district?/*",
    component: _144d51d6,
    name: "propertyType-continent-country-city-d-district-all"
  }, {
    path: "/:propertyType/:continent/:country/:city/*",
    component: _493609ba,
    name: "propertyType-continent-country-city-all"
  }, {
    path: "/:propertyType/:continent/:country/:city/d/:district",
    components: {
      default: _144d51d6
    }
  }, {
    path: "/:propertyType/:continent/:country/:city/f/:filter",
    components: {
      default: _493609ba
    }
  }, {
    path: "/:propertyType/:continent/:country/:city",
    components: {
      default: _493609ba
    }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
